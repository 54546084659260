import React, { useState } from 'react';
import { FaRegTrashAlt } from 'react-icons/fa';

const style = {
  li: `flex justify-between bg-slate-200 p-4 my-2 capitalize`,
  liComplete: `flex justify-between bg-slate-400 p-4 my-2 capitalize`,
  row: `flex`,
  text: `ml-2 cursor-pointer`,
  textComplete: `ml-2 cursor-pointer line-through`,
  button: `cursor-pointer flex items-center`,
  input: `border p-2 w-full text-xl`,
  editForm: `flex justify-between`,
};

const Todo = ({ todo, toggleComplete, deleteTodo, updateTodo }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [newText, setNewText] = useState(todo.text);

  const handleEdit = () => {
    setIsEditing(!isEditing);
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    updateTodo(todo.id, newText);
    setIsEditing(false);
  };

  return (
    <li className={todo.completed ? style.liComplete : style.li}>
      <div className={style.row}>
        <input
          type="checkbox"
          checked={todo.completed ? 'checked' : ''}
          onChange={() => toggleComplete(todo)}
        />
        {!isEditing ? (
          <p
            onClick={() => toggleComplete(todo)}
            className={todo.completed ? style.textComplete : style.text}
          >
            {todo.text}
          </p>
        ) : (
          <form onSubmit={handleUpdate} className={style.editForm}>
            <input
              type="text"
              value={newText}
              onChange={(e) => setNewText(e.target.value)}
              className={style.input}
            />
            <button type="submit" className={style.button}>
              Save
            </button>
          </form>
        )}
      </div>
      <div>
        <button onClick={handleEdit} className={style.button}>
          Edit
        </button>
        <button onClick={() => deleteTodo(todo.id)} className={style.button}>
          <FaRegTrashAlt />
        </button>
      </div>
    </li>
  );
};

export default Todo;
