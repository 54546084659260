import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// TODO: Replace the following with your app's Firebase project configuration
// See: https://support.google.com/firebase/answer/7015592
const firebaseConfig = {
  apiKey: "AIzaSyAE1CC3Me3RMIWxq5mMDPyiyfRvusq6Od0",
  authDomain: "todo-a8666.firebaseapp.com",
  databaseURL: "https://todo-a8666-default-rtdb.firebaseio.com",
  projectId: "todo-a8666",
  storageBucket: "todo-a8666.appspot.com",
  messagingSenderId: "377669921197",
  appId: "1:377669921197:web:88dcfc3a8ae0bcd4cc1924",
  measurementId: "G-1QR18LLSW2"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);


// Initialize Cloud Firestore and get a reference to the service
export const db = getFirestore(app);
